.sidebar{
  height: 100%;
  width: 85px;
  background-color: #E3EDF8;
  color: #013A70;
  top: 0;
  left: 0;
  padding: 10px;
  text-align: center;
  border-right: 1px solid rgba(0,0,0,0.1);

  @media(max-width: 1024px){
    left: -85px;
    position: fixed;
    //top: 55px;
    z-index: 99999;
  }

  &__collapse{

    button.btn-collapse{
      font-size: 24px;
      border: 0;
      height: 48px;
      background-color: transparent;
      width: auto;
      max-width: 100%;
      min-width: 0;
      &:focus, &:active{
        outline: none;
      }

      &.close{
        display: none;
        font-size: 12px;
        align-items: center;
        font-weight: 100;
        font-family: "Montserrat", sans-serif;
        color: $dark-blue;
        margin-left: auto;
        margin-right: 15px;
        svg{
          font-size: 24px;
          margin-right: 15px;
        }
      }

      &.open{

      }
    }
  }

  &__profile{
    text-align: center;
    margin: 30px auto;
    &-icon{
      margin: 0 auto;
      border: 1px solid $dark-blue;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 100;
    }
    &-name{
      display: none;
    }
  }

  &__cta{
    button.btn-aqua{
      border: 0;
      height: 48px;
      line-height: 24px;
      width: auto;
      max-width: 100%;
      min-width: 0;
      font-size: 0;
      text-align: center;
      position: relative;

      &:hover{

        &:before{
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          border-top: 1px solid;
          border-left: 1px solid;
          top: 20px;
          left: 63px;
          background: #000000;
          transform: rotate(45deg);
          color: transparent;
        }

        &:after{
          content: attr(data-after-content); //inserir aqui nome dinamico do atributo title
          display: block;
          background-color: #000000;
          color: #FFFFFF;
          font-size: 16px;
          position: absolute;
          left: 66px;
          top: 10px;
          width: 140px;
          padding: 2px;
          border-radius: 3px;
          z-index: 9999;
          letter-spacing: 0.24px;
          font-weight: 500;
          font-family: "Roboto", sans-serif;
        }

      }

      svg{
        font-size: 24px;
      }
      span{
        display: none;
      }
    }
  }

  nav{
    margin-top: 50px;
    text-align: center;
    ul{
      list-style: none;
      li{
        padding: 10px 0;
        a{
          display: flex;
          justify-content: center;
          font-size: 0;
          color: $gray-blue2;
          position: relative;
          svg{
            font-size: 24px;
          }

          &:hover{

            &:before{
              content: "";
              position: absolute;
              width: 8px;
              height: 8px;
              border-top: 1px solid;
              border-left: 1px solid;
              top: 10px;
              left: 63px;
              background: #000000;
              transform: rotate(45deg);
              color: transparent;
            }

            &:after{
              content: attr(data-after-content);  //inserir aqui nome dinamico do atributo title
              display: block;
              background-color: #000000;
              color: #FFFFFF;
              font-size: 16px;
              position: absolute;
              left: 66px;
              top: 0px;
              width: 140px;
              padding: 2px;
              border-radius: 3px;
              z-index: 9999;
            }

          }
        }
        span{
          opacity: 0.5;
          display: flex;
          justify-content: center;
          font-size: 0;
          color: $gray-blue2;
          svg{
            font-size: 24px;
          }
        }
      }
    }
  }

  &.opened{
    width: 100%;
    max-width: 270px;
    transition: all 1s;
    z-index: 9999;

    @media(max-width: 1024px){
      left: 0;
      overflow-y: auto;
    }

    .sidebar__collapse{
      button.btn-collapse{
        &.close{
          display: flex;
        }
        &.open{
          display: none;
        }
      }
    }

    .sidebar__profile{
      margin: 30px 12.5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-name{
        display: block;
      }
      &-icon{
        margin: 0 15px 0 0;
      }
    }

    .sidebar__cta{
      margin: 30px 12.5px;
      text-align: left;

      button.btn-aqua{
        font-size: 14px;
        line-height: 18px;
        width: 100%;
        padding: 8px 16px;
        &:after, &:before{
          display: none;
        }



        &:hover{
          span{
            color: #212529;
          }

        }
        span{
          display: block;
          color: #FFFFFF;
          svg{
            margin-right: 10px;
            display: none;
          }
        }
      }
    }

    nav{
      text-align: left;
      margin-left: 12.5px;
      ul{
        list-style: none;
        li{
          text-align: left;
          padding: 10px 0;
          a{
            font-size: 14px;
            line-height: 24px;
            color: $gray-blue2;
            justify-content: flex-start;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
            &:after, &:before{
              display: none;
            }
            svg{
              margin-right: 15px;
            }
            &:hover{
              color: $aqua;
              text-decoration: none;
              svg{
                color: $aqua;
              }
            }
          }
          span{
            opacity: 0.5;
            font-size: 14px;
            line-height: 24px;
            color: $gray-blue2;
            justify-content: flex-start;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
            svg{
              margin-right: 15px;
            }
            &:hover{
              color: $aqua;
              text-decoration: none;
              svg{
                color: $aqua;
              }
            }
          }
        }
      }
    }
  }
}
