.stepper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 100px;

  @media(max-width: 1024px){
    margin-top: 50px;
    justify-content: space-around;
  }

  label {
    background-color: $light-orange;
    color: #ffffff;
    padding: 5px;
    border-radius: 12px;
    height: 24px;
    display: flex;
    text-align: center;
    width: 84px;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -40px;

    @media(max-width: 1024px){
      position: relative;
      top: 0;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $light-orange;
      position: absolute;
      bottom: -5px;
    }
    &.step-init {
      left: -42px;
      @media(max-width: 1024px){
        left: inherit;
      }
    }
    &.step-middle {
      left: 50%;
      transform: translate(-50%, 0);
      @media(max-width: 1024px){
        left: inherit;
        transform: inherit;
      }
    }
    &.step-finish {
      right: -42px;
      @media(max-width: 1024px){
        right: inherit;
      }
    }
    &.step-disabled {
      opacity: 0.5;
    }
  }

  progress[value] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    flex-basis: 100%;
    @media(max-width: 1024px){
      width: calc(100% - 120px);
      flex-basis: calc(100% - 120px);
    }
  }

  progress[value]::-webkit-progress-bar {
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) inset;
  }

  progress[value]::-webkit-progress-value {
    background-color: $light-orange;
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }
}
