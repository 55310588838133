.overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999;
  overflow: hidden;
}

.modal {
  position: absolute;
  z-index: 999999;
  background-color: #ffffff;
  padding: 50px 50px 20px 50px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  text-align: center;
  height: auto;

  .modal__content {
    p {
      text-align: center;
      font-size: 18px;
      font-family: "Montserrat",sans-serif;
    }
  }

  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .btn-container {
    display: flex;
    margin-top: 50px;
    justify-content: space-evenly;
    button {
      flex-basis: 50%;
      margin: 0 10px;
    }
  }
}
