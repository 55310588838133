@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

@mixin font-face($name, $file) {
  @font-face {
    font-family: "#{$name}";
    src: url("../../fonts/#{$file}.eot");
    src: url("../../fonts/#{$file}.eot?#iefix") format("embedded-opentype"),
      url("../../fonts/#{$file}.woff") format("woff"),
      url("../../fonts/#{$file}.ttf") format("truetype"),
      url("../../fonts/#{$file}.svg?#webfont") format("svg");
  }
}

@include font-face("Gotham-Medium", "Gotham-Medium");
@include font-face("Gotham-Thin", "Gotham-Thin");
@include font-face("Gotham-Bold", "Gotham-Bold");
@include font-face("Gotham-Light", "Gotham-Light");
@include font-face("Gotham-Ultra", "Gotham-Ultra");

$gotham-thin: "Gotham-Thin", sans-serif;
$gotham-bold: "Gotham-Bold", sans-serif;
$gotham-ultra: "Gotham-Ultra", sans-serif;

//$gotham-light: "Gotham-Light", sans-serif;
//$gotham-medium: "Gotham-Medium", sans-serif;
$gotham-light: 'Montserrat', sans-serif;
$gotham-medium: 'Montserrat', sans-serif;

$roboto: "Roboto", sans-serif;
$font1: 'Montserrat', sans-serif;

html,
body {
  font-size: 16px;
  hyphens: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: $gotham-light;
  letter-spacing: 0.24px;
  font-weight: 500;
  word-break: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $gotham-light;
  word-break: break-word;
  a{
    &:hover{
      color: $black;
      text-decoration-color: $green;
    }
  }
}
