.form-group{
  position: relative;
  margin-bottom: 1.5rem;

  // .form-control-placeholder {
  //   position: absolute;
  //   top: 0;
  //   padding: 7px 0 0 13px;
  //   transition: all 200ms;
  //   opacity: 0.5;
  // }

  label{
    display: block;
    font-family: "Montserrat",sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #536680;
    position: absolute;
    top: 17px;
    font-size: 16px;
    left: 20px;
    margin: 0 0 5px 0;
    z-index: 0;
  }

  input, textarea, select {
    background-color: transparent;
    border: 0;
    width: 100%;
    border: 1px solid #e8eff8;
    font-family: "Montserrat",sans-serif;
    font-size: 16px;
    line-height: 22px;
    color: #536680;
    padding: 10px 15px;
    border-radius: 5px;
    height: 50px;

    &[value]:not([value=""]){
      padding: 22px 15px 5px 14px;
      &+label{
        font-size: 12px;
        line-height: 12px;
        top: 10px;
        left: 15px;
      }
    }

    &:disabled{
      background-color: rgba(0,0,0,0.05);
    }
  }

  select:valid{
    padding: 22px 15px 5px 10px;
    &+label{
      font-size: 12px;
      top: 10px;
      left: 15px;
    }
  }

  &.error{
    .form-control{
      border: 1px solid #FE4901;
    }
  }

  
  .form-control{
    padding: 10px 20px;
    border: 0;
    height: 50px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 1px solid #e8eff8;
    //box-shadow: inset 0 0 0 38px #fff;
    @include font($family: $gotham-light, $size: 16px, $color: $text-color);
    &.readonly {
      height: auto;
      padding: 0;
    }
    & + span {
      margin-top: -21px;
    }
    &:valid{
      //padding-top: 25px;
      // &+label{
      //   position: absolute;
      //   font-size: 10px;
      //   top: 5px;
      //   left: 20px;
      // }
    }
  }


  
  .form-control:focus + .form-control-placeholder,
  .form-control:valid + .form-control-placeholder {
    color: $text-color;
    font-size: 12px;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    top: 25px;
  }
  span{
    font-size: 10px;
    color: $blue1;
    display: none;
  }
  .feedback-invalid{
    color: #FE4901;
    width: 100%;
    display: block;
    position: relative;
    margin-top: 5px;
    padding: 0 0.75rem;
    font-size: 12px;
    svg{
      position: absolute;
      color: #FF980D;
      right: -35px;
      top: -42px;
      font-size: 24px;
      @media (max-width: 540px) {
        right: -25px;
      }
    }
  }

  .btn--password {
    position: absolute;
    right: 0;
    top: 0;
  }
  .labelFloat {
    position: absolute;
    font-size: 10px;
    top: 2px;
    left: 20px;
  }
  a {
    font-size: 12px;
    margin-top: 15px;
    display: inline-block;
  }
}
.form-row > .col, .form-row > [class*="col-"] {
  padding-right: 7px;
  padding-left: 7px;
}
.form-row {
  margin-right: -7px;
  margin-left: -7px;
}

.switch {
  // position: absolute;
  // margin-left: -9999px;
  // visibility: hidden;
}

.switch-group{
  display: flex;
  align-items: center;
  span{
    font-size: 14px;
  }
}

.switch__container{
  margin-right: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.switch + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch--shadow{
  width: 30px;
  position: absolute;
  top: 0;
  z-index: 9999;
  opacity: 0;
}

.switch--shadow + label {
  padding: 2px;
  width: 30px;
  height: 15px;
  background-color: #dddddd;
  border-radius: 10px;
  margin: 0;
}

.switch--shadow + label:before,
.switch--shadow + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: '';
}
.switch--shadow + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 10px;
  transition: all 0.4s;
}
.switch--shadow + label:after {
  width: 10px;
  height: 10px;
  top: 2px;
  left: 3px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}
.switch--shadow:checked + label:before {
  background-color: #708CA7;

}
.switch--shadow:checked + label:after {
  transform: translateX(15px);
}
