.boxCotacao{
  text-align: center;
  padding: 10px;
  border: 2px solid #DBE7F3;
  border-radius: 20px;
  width: 170px;
  color: #708CA7;
  cursor: pointer;
  position: relative;
  margin-right: 60px;

  @media(max-width: 768px){
    margin: 0 0 15px 0;
    width: 100%;
    &__container{
      display: flex;
      align-items: center;
    }
  }

  &:last-child{
    margin-right: 0;
  }

  &:hover, &.active{
    border-color: $aqua;
    &:after{
      content: "";
      width: 15px; 
      height: 15px; 
      background-color: #FFFFFF;
      border-bottom: 2px solid #DBE7F3;
      border-left: 2px solid #DBE7F3;
      transform: rotate(135deg);
      position: absolute;
      bottom: -35px;
      z-index: 1;
      left: calc(50% - 7.5px);
      @media(max-width: 768px){
        display: none;
      }
    }
  }

  

  figure{
    width: 142px;
    height: 142px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.12) inset;
    border-radius: 15px;
    margin: 0 auto 20px auto;
    @media(max-width: 768px){
      width: 50px;
      height: 50px;
      margin: 0 15px 0 0;
      img{
        width: 100%;
        padding: 10px;
      }
    }
  }
  h6{
    font-family: $gotham-medium;
    font-size: 12px;
    margin: 0;
  }
  small{
    display: block;
    line-height: 12px;
    font-family: $gotham-medium;
    font-size: 9px;
  }
}