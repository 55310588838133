.tabs{
  display: flex;
  button{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $light-blue3;
    min-width: 259px;
    height: 55px;
    border-radius: 15px 15px 0 0;
    margin-right: 0px;
    color: $black;
    font-family: "Montserrat", sans-serif;
    background-color: #FFFFFF;

    @media(max-width: 1280px){
      min-width: 0;
      padding: 0 20px;
      font-size: 14px;
      height: 48px;
    }

    &:focus{
      outline: 0;
    }
    &:hover{
      text-decoration: none;
    }
    &.active{
      color: $dark-blue2;
      font-weight: 700;
      background-color: $light-blue3;
    }
  }
}
.tabsContent{
  position: relative;
  top: -2px;
  border: 2px solid $light-blue3;
  padding: 50px;
  height: calc(100% - 50px);
  &__tab{
    display: none;
    &.active{
      display: block;
    }
  }

  @media(max-width: 1024px){
    padding: 30px 15px;
  }
}