


//colors
$light-blue: #E8EFF8;
$light-blue2: #CDD8E6;
$light-blue3: #DBE7F3;


$blue1: #4A90E0;
$blue2: #0061BD;
$blue-green: #076B87;
$aqua: #0AC9C1;

$orange: #FE4901;
$light-orange: #FF980D;
$red: #C91D14;
$dark-blue: #003A70;
$dark-blue2: #002D57;
$dark-blue3: #1C3654;
$gray-blue: #949BA6;
$gray-blue2: #7C9AC0;
$gray-light: #FBFBFB;
$text-color: #536680;
$black: #303030;


$gradient-light-blue: linear-gradient(0deg, rgba(239,246,255,1) 0%, rgba(255,255,255,1) 100%);

.gradient-bg{
  background: $gradient-light-blue;
}
.gray-bg{
  background: $gray-light;
}
.row-page{
  padding: 0 162px;
}
.page-auth{
  display: flex;
  width: 100%;

  @media(max-width: 1024px){
    flex-direction: column;
  }

  .box-auth{
    padding: 40px 0 100px 0;
    // background-color: $light-blue;
    width: 100%;
    max-width: 1080px;
    min-width: 768px;
    display: flex;
    justify-content: center;
    position:relative;

    @media(max-width: 1540px){
      padding: 40px 0 40px 0;
    }

    &.bgAuth{
      &:before {
        content: url("../../images/bg-cadastro.png");
        width: 792px;
        height: 686px;
        position: absolute;
        top: -120px;
        right: -600px;
        pointer-events: none;        
      }
    }

    &.box-auth--success{
      &:before {
        content: url("../../images/bg-sucesso.png");
        width: 792px;
        height: 686px;
        position: absolute;
        top: -160px;
        right: -730px;
        pointer-events: none;
        @media(max-width: 1540px){
          top: -279px;
          right: -680px;
        }
      }
    }

    &.errorPage{
      &:before {
        content: url("../../images/bg-error.png");
        width: 792px;
        height: 686px;
        position: absolute;
        top: -150px;
        right: -500px;
        pointer-events: none;
      }
    }

    &.bgWellcome{
      &:before {
        content: url("../../images/bg-cadastro.png");
        width: 792px;
        height: 686px;
        position: absolute;
        top: -150px;
        right: -500px;
        pointer-events: none;
        @media(max-width: 1540px){
          top: -240px;
        }
      }
    }

    @media(max-width: 1024px){
      &:before {
        display: none;
      }      
    }

    @media(max-width: 1024px){
      min-width: 0;
      max-width: 100%;
      padding: 30px;
      // align-items: center;
      // text-align: center;
      text-align: left;
      height: 100%;
    }

    &__wrapper{
      width: 100%;
      max-width: 460px;
    }
    &--success {
      background-color: transparent;
      @media(max-width: 1024px){
        height: 100%;
        &+.bg-auth{
          display: none;
        }
      }
      .box-auth__wrapper{
        margin: auto;
      }
    }
  }
  .bg-auth{
    // background-image: url("../../images/bg-cadastro.png");
    background: none!important;
    width: 100%;
    background-position: center center;

    @media(max-width: 1024px){
      height: 100%;
    }

  }
  .bg-lock{
    background-image: url("../../images/bg-login.png");
    width: 100%;
    background-size: cover;
    background-position: center center;

    @media(max-width: 1024px){
      height: 100%;
      display: none;
    }
  }
}

h1{
  margin-bottom: 37px;
  margin-top: 57px;
  @include font($family: $gotham-light, $size: 40px, $color: $dark-blue, $lh: 64px);
}

p{
  @include font($family: $roboto, $size: 18px, $color: $black, $weight: 400, $lh: 32px);
}

.logo{
  margin-bottom: 50px;
  display: block;
}


.link{
  text-decoration: underline;
  display: block;
  margin: 75px auto 0 auto;
  text-align: center;
  @include font($family: $gotham-light, $size: 12px, $color: $blue1);
}

.mt-22 {
  margin-top: 22px;
}

.loading{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  p{
    color: $black;
    font-family: $roboto;
    font-size: 14px;
    width: 100%;
    max-width: 450px;
    line-height: 20px;
  }
  img{
    animation: spin 1s linear infinite;
    margin-top: 50px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.infoBox{
  margin-top: 50px;
  background-color: $blue-green;
  padding: 25px 25px 15px 25px;
  border-radius: 10px;
  position: relative;
  box-shadow: 1px 1px 5px 5px rgba(0,0,0,0.1);

  button.btn-aqua{
    box-shadow: 1px 1px 5px 5px rgba(0,0,0,0.1);
    position: absolute;
    right: -15px;
    width: 40px;
    height: 40px;
    bottom: 15px;
    @media(max-width: 768px){
      bottom: -20px;
      right: 20px;
    }
    svg{
      color: #FFFFFF;
      path{
        stroke: #FFFFFF;
      }
    }
    &:hover{
      color: #FFFFFF;
    }
  }
  ul{
    list-style: none;
    column-count: 3;
    column-gap: 15px;

    @media(max-width: 1024px){
      column-count: 1;
      column-gap: 0;
    }

    li{
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 10px;
      font-size: "Roboto", sans-serif;
      span{
        font-weight: 700;
        font-size: 12px;
      }
      &:before{
        content: "•";
        color: #C8D90B;
        margin-right: 5px;
        position: relative;
        top: 4px;
        font-size: 24px;
        line-height: 0;
      }
    }
  }
}

.oferta{
  position: relative;
  height: 80px;
  &:after{
    content: "";
    width: 12px;
    height: 12px;
    background-image: url("../../images/arrow-down.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 30px;
  }

  &__header{
    display: flex;
    figure{
      width: 100%;
      max-width: 130px;
      margin-right: 15px;
    }
    p{
      font-size: 12px;
      font-family: $roboto;
      font-weight: 300;
      margin-right: 50px;
      line-height: 14px;
      max-width: 230px;
    }
    .price{
      font-family: $gotham-medium;
      font-size: 16px;
      color: $aqua;
    }
  }

  &__content{
    display: none;
    p{
      font-size: 12px;
      font-family: $roboto;
      font-weight: 300;
      margin-right: 50px;
      line-height: 14px;
    }
  }

  &.open{
    height: auto;

    &:after{
      transform: rotate(0);
      bottom: 50px;
      top: auto;
    }

    .oferta__content{
      display: flex;
      flex-wrap: wrap;
      padding: 50px 0 80px 0;
      p{
        &:nth-child(odd){
          flex-basis: calc(30% - 50px);
        }
        &:nth-child(even){
          flex-basis: calc(70% - 50px);
        }
      }
    }
  }
}

#btn_logout{
  border: 0;
  background-color: transparent;
  outline: none;
}


.full-container{
  width: 100%;
  max-width: 1595px;
  margin: 0 auto;
  padding: 0 15px;
}

.content-full{
  width: 100%;
  display: flex;
  margin-top: -75px;
  height: 100%;
  .content{
    width: calc(100% - 85px);
    margin-top: 75px;
    @media(max-width: 1024px){
      width: 100%;
    }
  }
}


.form-error {
  width: 100%;
  height: 39px;
  border: 1px solid #FE4901;
  border-radius: 5px;
  margin-bottom: 30px;
  background-color: rgba(254, 73, 1, 0.1);
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0 10px;
  svg{
    margin-right: 10px;
    font-size: 28px;
    vertical-align: middle;
  }

  @media(max-width: 1024px){
    padding: 5px;
    height: auto;
    span{
      line-height: 20px;
    }
  }

  .form-error-message {
    color: #FE4901;
    line-height: 28px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
  }
}


.boxDatePicker{
  height: 120px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media(max-width: 1024px){
    height: auto;
  }
}


.vertical-timeline-element{
  .vertical-timeline-element-icon{
    svg{
      color: #FFFFFF;
    }
  }
}

.button__mobile{
  text-align: right;
  @media(max-width: 576px){
    text-align: left !important;
  }
}


#root{
  overflow: hidden;
}

.body-modal{
  overflow: hidden;
  #root{
    max-height: 100vh;
  }
}

.vertical-timeline-element-content{
  background-color: transparent;
  height: auto;
  box-shadow: none;
  border-bottom: 1px solid #DBE7F3;
}
.vertical-timeline-element-content-arrow{
  display: none;
}
.vertical-timeline{
  &:before{
    background: #DBE7F3 !important;
  }
}
.vertical-timeline-element-icon{
  background-color: #DBE7F3 !important;
  box-shadow: none;
  width: 40px;
  height: 40px;
  left: 0;
  svg{
    width: 15px;
    height: 20px;
    margin-left: -7px;
    margin-top: -10px;
  }
}

@media(max-width: 1024px){
  .vertical-timeline-element,
  .vertical-timeline-element-content,
  .vertical-timeline-element-content>.vertical-timeline-element-content-arrow+div{
    height: auto !important;
  }
  .vertical-timeline-element-content>.vertical-timeline-element-content-arrow+div p{
    position: relative;
  }
}

.titleButtonLine{
  margin-bottom: 20px;
  @media(max-width: 1024px){
    margin-bottom: 30px;
  }
}


input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

#toast{
  @media(max-width: 1024px){
    width: 100% !important;
    left: 0 !important;
  }
}

.pagination{
  margin-top: 50px;
  text-align: center;

  @media(max-width: 576px){
    display: flex;
    flex-wrap: wrap;
  }

  button{
    border-radius: 8px;
    letter-spacing: 1.3px;
    @include font($family: $gotham-medium, $size: 16px, $color: #ffffff);
    font-weight: 500;
    padding: 8px;
    min-width: 48px;
    border: 0;
    background-color: $dark-blue;
    border-color: $dark-blue;
    height: 48px;

    @media(max-width: 576px){
      order: 0;
      flex-basis: calc(25% - 10px);
      width: calc(25% - 10px);
      margin: 0 5px;
    }

    svg{
      font-size: 24px;
    }

    &:hover{
      background-color: $dark-blue3;
      border-color: $dark-blue3;
    }
  }
  span{
    margin: 0 20px;

    @media(max-width: 576px){
      order: 1;
      flex-basis: 100%;
      text-align: center;
      margin-top: 20px;
    }
  }
}