.header {
    color: $black;
    font-size: 12px;
    padding: 0 180px;
    background-color: rgba($color: #fff, $alpha: .7);
    position: relative;
    min-height: 55px;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-items: flex-end;
    align-items: center;
    z-index: 10;
    margin-bottom: 75px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);

    @media(max-width: 1024px){
        margin-bottom: 50px;
    }

    button {
      height: 36px !important;
      font-size: 14px;
      line-height: 18px;
      padding: 8px 16px;
      margin-right: 10px;
      &:hover{
        color: #212529!important;                
        }
    }

    @media(max-width: 1280px){
        padding: 0 30px;
    }

    &__logo{
      cursor: pointer;
        margin-right: auto;
        width: auto;
        img{
            height: 27px;
        }
    }

    &__mobile-menu{
        display: none;
        margin-left: 30px;
        //position: relative;
        .mobile-menu{
            //display: none;
            position: absolute;
            width: calc(100% - 60px);
            right: 30px;
            top: 50px;
            background-color: $dark-blue2;
            nav{
                display: block;
                button {
                    color: #FFFFFF;
                    border-bottom: 2px solid $aqua;
                    font-size: 16px;
                    &.active{
                        color: $aqua;
                    }
                }
            }
        }
        svg{
            width: 24px;
            height: auto;
        }
        @media(max-width: 992px){
            display: block;
        }
    }

    &__user {
        display: contents;

        @media(max-width: 1024px){
            font-size: 0;
        }

        @media(max-width: 992px){
            span{
                display: none;
            }
        }

        &__img{
            margin-right: 25px;
            margin-left: 14px;
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-image: url("../../images/ic_perfil.svg");
            background-size: cover;
            cursor: pointer;
            img {
                object-position: center;
                object-fit: cover;
            }
        }
    }
    &__leave {
      margin-left: 10px;
    }
    &__notifications {
        width: 35px;
        height: 35px;
        padding: 5px 0;
        position: relative;
        cursor: pointer;
        img {
            width: 19px;
        }
        &__counter{
            border-radius: 6px;
            padding: 0 2px;
            font-size: 8px;
            color: #fff;
            border: 1px solid #fff;
            background-color: $orange;
            height: 14px;
            position: absolute;
            left: 12px;
            top: 6px;
        }
    }

    &__sidebar__icon{
        display: none;
        @media(max-width: 1024px){
            display: block;
            svg{
                width: 32px;
                height: 32px;
            }
        }
    }

    nav{
        min-width: 50%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        // @media(max-width: 992px){
        //     display: none;
        // }

        @media(max-width: 992px){
            min-width: 0;
        }

        a {
            color: $black;
            padding: 0 14px;
            border-bottom: 4px solid transparent;
            height: 55px;
            display: flex;
            align-items: center;
            margin-bottom: -4px;
            &.active, &:hover {
                color: $black;
                border-color: $aqua;
                text-decoration: none;
            }
            @media(max-width: 992px){
                display: none;
            }
        }
        span {
            color: $black;
            padding: 0 14px;
            border-bottom: 4px solid transparent;
            height: 55px;
            display: flex;
            align-items: center;
            margin-bottom: -4px;
            opacity: 0.5;
            &.active, &:hover {
                color: $black;
                border-color: $aqua;
                text-decoration: none;
            }
            @media(max-width: 992px){
                display: none;
            }
        }
        .btn{
            //height: 35px;
            font-size: 14px;
            //padding: 5px 15px 0 15px;
            color: #FFFFFF;
            margin-right: 20px;
            @media(max-width: 768px){
                display: none;
            }
        }
    }
    &--page {
        padding-top: 55px;
    }
}
