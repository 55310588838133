.btn {
  border-radius: 8px;
  letter-spacing: 1.3px;
  @include font($family: $gotham-medium, $size: 16px, $color: #ffffff);
  font-weight: 500;
  &.btn-primary {
    background-color: $dark-blue;
    border-color: $dark-blue;
    height: 50px;
  }
  &.btn-outline-primary {
    background-color: transparent;
    border-color: $dark-blue;
    height: 50px;
    color: $dark-blue;
  }
  &.btn-danger {
    background-color: $orange;
    border-color: $orange;
  }
  &.btn-default {
    background-color: transparent;
    border-color: 0;
    color: $dark-blue;
    box-shadow: 5px 5px 15px 1px rgba(0, 58, 112, 0.1);
  }
  &.btn-aqua {
    background-color: $aqua;
    border-color: $aqua;
    height: 60px;
    @media(max-width: 992px){
      height: 50px;
    }
  }
  &.btn-outline-aqua {
    color: $aqua;
    background-color: transparent;
    border-color: $aqua;
    height: 60px;

    &:hover{
      color: #212529;
    }

  }
  &--icon {
    display: inline-block;
    width: 48px;
    height: 48px;
    padding: 0;
  }
  &-back {
    padding: 10px;
    color: $black;
    display: block;
    img {
      margin-right: 15px;
    }
  }

  &.btn-full {
    width: 100%;
  }

  @media(max-width: 992px){
    height: 50px;
    font-size: 12px;
    // padding: 0;
    font-weight: 500;
    font-size: 16px;
  }
  &:disabled{
    pointer-events: none;
  }
}

.btn-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;

  .btn {
    // flex: 1;
    margin-right: 15px;
    margin-bottom: 15px;
    min-width: fit-content;

    @media(max-width: 1024px){
      margin: 0 auto;
      justify-content: center;
      text-align: center;
    }
  }
}

button[disabled] {
  opacity: 0.5;
}

.btn-disabled{
  opacity: 0.5;
}
