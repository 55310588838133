.DayPicker{
  display: block;
  font-family: $roboto;
  .DayPicker-wrapper{
    padding-bottom: 0;
  }
  .DayPicker-Months{
    .DayPicker-Month{
      margin: 0;
      .DayPicker-Caption{
        font-family: $roboto, sans-serif;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .DayPicker-Week{
    .DayPicker-Day{
      padding: 10px 13px;
      font-weight: 700;
      font-size: 12px;
      @media(max-width: 1024px){
        padding: 0;
      }
    }
  }
  .DayPicker-Day--today:not(.DayPicker-Day--outside){
    border-radius: 50%;
    border-color: #AEBD09;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: rgba(174, 189, 9, 0.2);
    //border-radius: 50% 0 0 50%;
    color: #000000;
  }
  .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected , .DayPicker-Day--end{
    border-radius: 50%;
    background-color: #AEBD09 !important;
    color: #FFFFFF !important;
  }
  .DayPicker-Day--today{
    // border: 1px solid #AEBD09;
    background-color: #FFFFFF !important;
    color: #AEBD09;
    font-weight: bold !important;
  }
  
}

.date-picker{
  .DayPicker-NavBar{
    span{
      top: 3px;
      right: 0;
    }
  }

  input+div{
    border-radius: 10px;
    padding: 15px 30px;
    box-shadow: 3px 5px 10px 5px rgba(0,0,0,0.1);
    border: 1px solid #e8eff8 !important;
    margin-top: 5px;
  }
}

.DayPicker-Weekday{
  @media(max-width: 1024px){
    font-size: 12px;
  }
}
