.boxBasic {
    padding: 15px 15px 0;
    border-radius: 23px;
    border: 2px solid $light-blue3;
    &__profileImg {
        padding: 40px 0;
        text-align: center;
        position: relative;
        &__img{
            border-radius: 50%;
            margin: 0px auto;
            width: 124px;
            height: 124px;
            overflow: hidden;
            img {
                object-fit: cover;
                min-height: 0;
            }
        }
        .btn--icon {
            border-radius: 50%;
            margin-top: -40px;
        }
    }
}
.boxPerfil{
    color: $black;
    border-radius: 4px;
    margin-bottom: 15px;
    background-color: #fff;
    display: flex;
    &__left {
        padding: 45px 30px 30px;
        text-align: center;
        background: $gradient-light-blue;
        min-height: 100%;
        width: 33%;
        .perfil {
            margin: 0 auto;
            width: 125px;
            height: 125px;
            border-radius: 50%;
            overflow: hidden;
            img {
                object-fit: cover;
                object-position: center;
            }
        }
    }
    &__title {
        display: flex;
        align-items: center;
        h2 {
            flex: 1;
            font-size: 18px;
            span {
                color: $gray-blue;
                font-size: 12px;
                margin-left: 40px;
            }
        }
        .btn--icon {
            margin-right: -12px;
        }
    }
    &__content {
        flex: 1;
        padding: 45px 30px 30px;
        .form-group {
            margin-bottom: 25px;
            font-size: 14px;
            label{
                color: $gray-blue2;
                font-size: 12px;
            }
            .emptyLabel{
                border-radius: 0;
                text-align: left;
                letter-spacing: normal;
                color: $gray-blue2;
                font-size: 12px;
                padding: 4px 0;
                border-bottom: 1px solid $light-blue2;
                width: 100%;
                position: relative;
                &:after {
                    content: '';
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    right: 5px;
                    background-image: url("../../images/icons/plus.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            
        }

    }

}